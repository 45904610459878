<div class="details-form">
    <div class="form-group row">
        <h5 class="col-12" translate>NAME</h5>
        <div class="col-12">
            <span>{{ remoteAccess.name }}</span>
        </div>
    </div>
    <div class="form-group row">
        <h5 class="col-12" translate>TYPE</h5>
        <div class="col-12">
            <span>{{ remoteAccess.type | uppercase | translate }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="remoteAccess.resourceTags && remoteAccess.resourceTags.length">
        <h5 class="col-12" translate>TAGS</h5>
        <div class="col-12">
            <span *ngFor="let tag of remoteAccess.resourceTags; index as i"><span *ngIf="i > 0">, </span>{{ tag.name }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="remoteAccess.alertingProfile">
        <h5 class="col-12" translate>ALERTING_PROFILE</h5>
        <div class="col-12">
            <span>{{ remoteAccess?.alertingProfile?.name }}</span>
        </div>
    </div>
    <div class="form-group row" *ngIf="remoteAccess.accessKey">
        <h5 class="col-12" translate>SSH_KEY</h5>
        <div class="col-12">
            <span *ngIf="remoteAccess.accessKey.readOnly">{{ remoteAccess.accessKey.name }}</span>
            <a
                *ngIf="!remoteAccess.accessKey.readOnly && remoteAccess.accessKey.hasRsaPrivate"
                href="/api/access_keys/{{ remoteAccess.accessKey.id }}/download"
            >
                <fa-icon icon="download" size="sm"></fa-icon>
                {{ remoteAccess.accessKey.name }}
            </a>
        </div>
    </div>
    <ng-container *ngIf="remoteAccess.type === 'zixi'">
        <div class="form-group row">
            <h5 class="col-12" translate>HOST</h5>
            <div class="col-12" *ngFor="let tunnel_server of remoteAccess?.Customer?.tunnel_servers">
                <span>{{ tunnel_server.dns_prefix }}{{ tunnelersHost }}</span>
            </div>
        </div>
        <div class="form-group row" *ngIf="remoteAccess.tunnel_username">
            <h5 class="col-12" translate>USERNAME</h5>
            <div class="col-12">
                <span>{{ remoteAccess?.tunnel_username }}</span>
            </div>
        </div>
        <div class="form-group row" *ngIf="remoteAccess.tunnel?.port">
            <h5 class="col-12" translate>TUNNEL_PORT</h5>
            <div class="col-12">
                <span>{{ remoteAccess?.tunnel?.port }}</span>
            </div>
        </div>
        <div class="form-group row" *ngIf="remoteAccess.status && remoteAccess.status.source_ip">
            <h5 class="col-12" translate>SOURCE_IP</h5>
            <div class="col-12">
                <span>{{ remoteAccess?.status.source_ip }}</span>
            </div>
        </div>
    </ng-container>
    <div class="form-group mb-0 row" [ngClass]="{ 'd-none': !canEdit }">
        <h5 class="col-12" translate>OPEN</h5>
        <div class="col-12">
            <a href="{{ remoteAccess.configure_link }}" target="_blank">
                {{ "LINK" | translate }}
                <fa-icon icon="external-link-alt" size="sm"></fa-icon>
            </a>
        </div>
    </div>
</div>
