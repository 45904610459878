import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { RemoteAccessService } from "../remote-access.service";
import { SharedService } from "../../../services/shared.service";
import { RemoteAccess } from "../remote-access";
import { AccessKey } from "../../../models/shared";
import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { UntypedFormControl, Validators } from "@angular/forms";
@Component({
    selector: "app-remote-access-form",
    templateUrl: "./remote-access-form.component.html",
    providers: [TitleCasePipe]
})
export class RemoteAccessFormComponent implements OnInit, OnDestroy {
    remoteAccess: RemoteAccess;
    existingRemoteAccess: RemoteAccess;
    remoteAccessName: string;
    remoteAccessNames: string[];
    action: string;
    accessKeys: AccessKey[];
    videonDevices: { name: string; guid: string }[] = [];

    loading = true;
    saving = false;
    loadingInProgress = false;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    constants = Constants;

    private remoteAccessSubscription: Subscription;
    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name)
    ]);
    apiKeyControl = new UntypedFormControl("", [Validators.required]);

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private sharedService: SharedService,
        private rs: RemoteAccessService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.remoteAccessName = params.get("name");
            this.action = params.get("action");
            if (this.remoteAccessName) {
                this.remoteAccess = Object.assign({}, this.rs.getCachedRemoteAccess(this.remoteAccessName));
                this.existingRemoteAccess = _.cloneDeep(this.remoteAccess);

                this.prepForm();
                // Check if remoteAccess found in cache, if not get remoteAccess
                if (this.sharedService.isEmptyObject(this.remoteAccess)) {
                    this.rs.refreshRemoteAccessList();

                    this.rs.remoteAccessObservable$.pipe(take(1)).subscribe(async () => {
                        this.remoteAccess = Object.assign({}, this.rs.getCachedRemoteAccess(this.remoteAccessName));

                        this.remoteAccess = await this.rs.refreshRemoteAccess(this.remoteAccess.id).toPromise();
                        this.existingRemoteAccess = _.cloneDeep(this.remoteAccess);

                        this.prepForm();
                        if (this.action === "clone") {
                            this.remoteAccess.name = "";
                        }
                        this.loading = false;
                    });
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        });
    }
    prepForm() {
        if (this.remoteAccess) {
            this.tagsControl.setValue(this.remoteAccess.resourceTags);
            if (this.action === "edit") {
                this.nameControl.setValue(this.remoteAccess.name);

                if (this.remoteAccess.api_key) this.apiKeyControl.setValue(this.remoteAccess.api_key);
            }
        }
        this.setTitle();
    }

    setTitle() {
        // Set Title
        this.titleService.setTitle(
            this.translate.instant("REMOTE_ACCESS") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.remoteAccess && this.remoteAccess.name ? this.remoteAccess.name : "")
        );
    }

    ngOnInit() {
        this.nameControl.setValue(null);
        this.apiKeyControl.setValue(null);
        this.tagsControl.setValue([]);

        if (this.action) {
            if (this.action === "edit") {
                this.isEdit = true;
            }
            if (this.action === "clone") {
                this.isClone = true;
                this.remoteAccess.name = "";
            }

            if (!this.remoteAccess.use_http) this.remoteAccess.use_http = 0;
        }

        if (!this.remoteAccess && !this.isClone && !this.isEdit) {
            this.remoteAccess = new RemoteAccess();
            this.remoteAccess.name = "";
            this.remoteAccess.resourceTags = [];
            this.remoteAccess.use_http = 0;
            this.remoteAccess.accessKey = null;
            this.remoteAccess.type = "zixi";
        }

        this.prepForm();

        this.rs.refreshRemoteAccessList();

        this.remoteAccessSubscription = this.rs.remoteAccessObservable$.subscribe((remoteAccess: RemoteAccess[]) => {
            this.remoteAccessNames = _.map(remoteAccess, "name");
            // If Edit remove the current remoteAccessName from remoteAccessNames list
            if (this.isEdit) {
                this.remoteAccessNames = _.without(this.remoteAccessNames, this.remoteAccessName);
            }
        });

        this.sharedService
            .getAccessKeys()
            .pipe(take(1))
            .subscribe((keys: AccessKey[]) => {
                this.accessKeys = keys;
            });
    }

    ngOnDestroy() {
        this.remoteAccessSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.nameControl.value.trim(),
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.remoteAccess.alertingProfile.id,
            remote_access_key_id: this.remoteAccess.type === "zixi" ? this.remoteAccess.accessKey.id : null,
            use_http: this.remoteAccess.use_http,
            type: this.remoteAccess.type,
            api_key: this.isEdit
                ? undefined
                : this.remoteAccess.type === "videon"
                ? this.apiKeyControl.value.trim()
                : null,
            device_id: this.remoteAccess.type === "videon" ? this.remoteAccess.device_id : null
        };

        if (this.isEdit) {
            const ignores = [];
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingRemoteAccess, ignores);
            //
            const result = await this.rs.updateRemoteAccess(this.remoteAccess, changedData);
            if (result !== false) {
                this.saving = false;
                this.router.navigate([Constants.urls.remote_access, this.remoteAccess.name]);
            } else this.saving = false;
        } else {
            const result = await this.rs.addRemoteAccess(model);
            if (result !== false) {
                this.saving = false;
                this.router.navigate([Constants.urls.remote_access, model.name]);
            } else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone) this.router.navigate([Constants.urls.remote_access, this.remoteAccessName]);
        else this.router.navigate([Constants.urls.remote_access]);
    }

    async loadVideonDevices() {
        this.loadingInProgress = true;

        try {
            const devices = await this.rs.listVideoDevices(this.apiKeyControl.value.trim());
            this.videonDevices = devices.map(d => ({
                name: `${d.product_name} - ${d.serial_number}`,
                guid: d.device_guid
            }));
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }

        this.loadingInProgress = false;
    }
}
