import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { RemoteAccessService } from "../remote-access.service";
import { RemoteAccess } from "../remote-access";

@Injectable({
    providedIn: "root"
})
export class RemoteAccessListResolverService implements Resolve<RemoteAccess[]> {
    constructor(private rs: RemoteAccessService) {}

    resolve(): Observable<RemoteAccess[]> | Observable<never> {
        return new Observable((observe: Subscriber<RemoteAccess[]>) => {
            this.rs.refreshRemoteAccessList();
            this.rs.remoteAccessObservable$.subscribe((remoteAccess: RemoteAccess[]) => {
                observe.next(remoteAccess);
                observe.complete();
            });
        });
    }
}
