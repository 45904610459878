<div class="panel-container">
    <div id="left-container" class="left-container" #leftContainer [ngClass]="{ rowSelected: remoteAccessName }">
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 translate title="{{ 'REMOTE_ACCESS' | translate }}">REMOTE_ACCESS</h1>
            </div>
            <div class="actions">
                <div class="form-inline">
                    <button
                        type="button"
                        class="btn btn-outline-primary me-2"
                        routerLink="/{{ urls.remote_access }}/new"
                        title="{{ 'ADD' | translate }}"
                        [disabled]="
                            !resourceTags?.length ||
                            (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
                        "
                    >
                        <fa-icon icon="plus" size="sm"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!remoteAccessName">{{ "ADD" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!remoteAccessName">{{ "REFRESH" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- List Panel -->
        <div id="list-panel" class="list-panel" #listPanel [ngClass]="{ rowSelected: remoteAccessName }">
            <div class="list-container overflow-y-hidden">
                <!-- Table -->
                <app-table-list
                    [tableName]="'remote_access'"
                    [displayTableName]="'REMOTE_ACCESSES' | translate"
                    [data]="remoteAccess$ | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [(selectedRows)]="selectedRows"
                    (rowSelected)="selectRow($event)"
                    [showReport]="false"
                    [isColumnSelectionNeeded]="true"
                >
                    <!-- Bulk Actions -->
                    <div *ngIf="!remoteAccessName && selectedRows?.length > 0">
                        <app-zx-action-buttons
                            (multiToggleMute)="multiToggleMute($event)"
                            (multiEdit)="multiEdit($event)"
                            (multiEnable)="multiToggleState(true)"
                            (multiToggleState)="multiToggleState($event)"
                            (multiDelete)="multiDelete($event)"
                        >
                        </app-zx-action-buttons>
                    </div>
                </app-table-list>
            </div>
        </div>
    </div>
    <zx-list-resize *ngIf="remoteAccessName" (resizing)="resizing($event)"></zx-list-resize>
    <div class="right-container" [ngClass]="{ resizing: isResizing }" *ngIf="remoteAccessName">
        <router-outlet></router-outlet>
    </div>
</div>
