import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { take } from "rxjs/operators";

import { UsersService } from "../../../account-management/users/users.service";

import { RemoteAccess } from "../../remote-access";

@Component({
    selector: "app-remote-access-details",
    templateUrl: "./remote-access-details.component.html"
})
export class RemoteAccessDetailsComponent implements OnChanges {
    @Input() remoteAccess: RemoteAccess;
    @Input() canEdit: boolean;
    tunnelersHost: string;
    pwVisible = false;

    constructor(private userService: UsersService) {
        this.userService.getTunnelersHost.pipe(take(1)).subscribe(host => {
            this.tunnelersHost = host;
        });
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.remoteAccess) {
            if (changes.remoteAccess.previousValue && changes.remoteAccess.currentValue) {
                if (changes.remoteAccess.previousValue.id !== changes.remoteAccess.currentValue.id) {
                    this.pwVisible = false;
                }
            }
        }
    }
}
