import { Tag, AccessKey, ZixiObject } from "../../models/shared";

export class RemoteAccess extends ZixiObject {
    _frontData?: {
        sortableStatus: string;
    };
    accessKey: AccessKey;
    acknowledged: number;
    active_flapping: boolean;
    active_mute: boolean;
    configure_link: string;
    created_at: string;
    flapping: string;
    generalStatus: string;
    id: number;
    is_enabled: number;
    muted: number;
    muted_until: string;
    name: string;
    remote_access_key_id: number;
    resourceTags: Tag[];
    state: string;
    status?: {
        source_ip?: string;
    };
    tunnel_id: number;
    tunnel_username: string;
    updated_at: string;
    use_http: number;
    type: "zixi" | "videon";
    api_key: string | null;
    device_id: string | null;
}
