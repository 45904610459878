import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";

import { Constants } from "../../constants/constants";
import { RemoteAccessListComponent } from "./remote-access-list/remote-access-list.component";
import { RemoteAccessFormComponent } from "./remote-access-form/remote-access-form.component";
import { RemoteAccessComponent } from "./remote-access/remote-access.component";
import { RemoteAccessListResolverService } from "./remote-access-list/remote-access-list-resolver.service";

const remoteAccessRoutes: Routes = [
    {
        path: Constants.urls.remote_access + "/new",
        component: RemoteAccessFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_REMOTE_ACCESS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/cICKG"
                }
            ]
        }
    },
    {
        path: Constants.urls.remote_access + "/:name/:action",
        component: RemoteAccessFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.remote_access,
        component: RemoteAccessListComponent,
        resolve: {
            remoteAccess: RemoteAccessListResolverService
        },
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.REMOTE_ACCESS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/TwCKG"
                },
                {
                    menu: "HELP_MENU.CREATE_REMOTE_ACCESS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/cICKG"
                }
            ]
        },
        children: [
            {
                path: ":name",
                component: RemoteAccessComponent,
                data: {
                    help: [
                        {
                            menu: "HELP_MENU.REMOTE_ACCESS",
                            link: "https://zixidocumentation.atlassian.net/wiki/x/TwCKG"
                        },
                        {
                            menu: "HELP_MENU.CREATE_REMOTE_ACCESS",
                            link: "https://zixidocumentation.atlassian.net/wiki/x/cICKG"
                        }
                    ]
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(remoteAccessRoutes)],
    exports: [RouterModule]
})
export class RemoteAccessRouting {}
