<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit(form)" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">{{ "CREATE_NEW_REMOTE_ACCESS" | translate }}</h1>
                    <h1 *ngIf="isClone">{{ "CLONE_REMOTE_ACCESS" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_REMOTE_ACCESS" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>
                        <!-- Name -->
                        <app-input-generic-field
                            zmid="form_name"
                            [duplicateName]="remoteAccessNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                        ></app-input-generic-field>

                        <!-- Access Tags -->
                        <zx-access-tags-select
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            [isParentFormSubmitted]="form.submitted"
                            type="resource"
                        ></zx-access-tags-select>

                        <!-- Alerting Profile -->
                        <div class="form-group">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <zx-alerting-profile-select
                                name="alertingProfile"
                                [(model)]="remoteAccess.alertingProfile"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                            ></zx-alerting-profile-select>
                        </div>
                    </section>
                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- Type -->
                        <fieldset class="form-group">
                            <legend for="type">{{ "TYPE" | translate }}</legend>
                            <mat-button-toggle-group name="ra_type" aria-label="type" [(ngModel)]="remoteAccess.type">
                                <mat-button-toggle [value]="'zixi'" [disabled]="isEdit">{{ "ZIXI" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="'videon'" [disabled]="isEdit">{{ "VIDEON" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- SSH Key -->
                        <div class="form-group" *ngIf="remoteAccess.type === 'zixi'">
                            <label for="remote_access_key_id" [ngClass]="{ 'is-invalid': form.submitted && key.errors }">
                                {{ "SSH_KEY" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><fa-icon icon="info-circle" [ngbTooltip]="SSHContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                <ng-template #SSHContent>{{ "TOOLTIP.SSH_KEY" | translate }} > {{ "SSH_KEYS" | translate }}</ng-template>
                            </label>
                            <ng-select
                                id="remote_access_key_id"
                                name="remote_access_key_id"
                                required
                                [items]="accessKeys"
                                [clearable]="false"
                                bindLabel="name"
                                placeholder="{{ 'SELECT' | translate }} {{ 'SSH_KEY' | translate }}"
                                [(ngModel)]="remoteAccess.accessKey"
                                #key="ngModel"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && key.errors }"
                            >
                            </ng-select>
                            <div *ngIf="key.invalid" class="invalid-feedback">
                                <div *ngIf="key.errors.required">{{ "SSH_KEY" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- HTTPS/HTTP -->
                        <fieldset class="form-group" *ngIf="remoteAccess.type === 'zixi'">
                            <legend for="use_http">{{ "HTTP/HTTPS" | translate }}</legend>
                            <mat-button-toggle-group name="use_http" aria-label="use http" [(ngModel)]="remoteAccess.use_http">
                                <mat-button-toggle [value]="0">{{ "AUTO_HTTPS" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="1">{{ "HTTP" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>

                        <!-- Videon API Token -->
                        <app-input-generic-field
                            *ngIf="remoteAccess.type === 'videon' && !isEdit"
                            zmid="form_videon_api_token"
                            [formControl]="apiKeyControl"
                            [textFieldName]="'ACCESS_TOKEN' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                        >
                            <button
                                type="button"
                                (click)="loadVideonDevices()"
                                class="mt-3 btn btn-outline-secondary"
                                [disabled]="disabled || loadingInProgress"
                            >
                                {{ "LOAD_DEVICES" | translate }}
                                <span *ngIf="loadingInProgress" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span></button
                        ></app-input-generic-field>

                        <!-- Device -->
                        <div class="form-group" *ngIf="remoteAccess.type === 'videon' && !isEdit">
                            <label for="device_id" [ngClass]="{ 'is-invalid': form.submitted && device_id.errors }">
                                {{ "DEVICE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon>
                            </label>
                            <ng-select
                                id="device_id"
                                name="device_id"
                                required
                                [items]="videonDevices"
                                [clearable]="false"
                                bindValue="guid"
                                bindLabel="name"
                                placeholder="{{ 'SELECT' | translate }} {{ 'DEVICE' | translate }}"
                                [(ngModel)]="remoteAccess.device_id"
                                #device_id="ngModel"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && device_id.errors }"
                            >
                            </ng-select>
                            <div *ngIf="device_id.invalid" class="invalid-feedback">
                                <div *ngIf="device_id.errors.required">{{ "DEVICE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                    </section>
                    <app-error [marginBottom]="false" [marginTop]="true"></app-error>
                    <hr />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
